import React, {useEffect} from 'react';
//import ReactGA from 'react-ga';
import ReactGA from "react-ga4";
import { Container, Image, Card } from 'react-bootstrap'
import './app.scss';
import crucifix from './images/PassingTheFaithOntoLovedOnes.jpg';
import worddoc from './docs/Synopsis - Passing the Faith onto Loved Ones.docx';
import pdf from './docs/Synopsis - Passing the Faith onto Loved Ones.pdf';
import wordbook from './docs/Book - Passing the Faith onto Loved Ones.docx';
import pdfbook from './docs/Book - Passing the Faith onto Loved Ones.pdf';
import rose from './images/rose.svg';
import passingTheFaith from './images/Passing the Faith.jpg';
import youTube from './images/YouTube_400.jpg';
import fromSomeone from './images/From Someone Who Loves You.jpg';
import Share from './Share';

function App() {

  // old: UA-168892917-1
  // new:  G-8VKQ203BC9
  useEffect(() => {
    ReactGA.initialize('G-8VKQ203BC9');
    if (process.env.NODE_ENV === 'production') {
      //ReactGA.pageview(window.location.pathname);
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, []);

  const gaTrack = (category, action) => {
    if (process.env.NODE_ENV === 'production') {
        ReactGA.event({ category, action })
    }
  }

  return (
    <Container className="book-container">
      <Image src={crucifix} className="img-fluid rounded" /> 
      <header>
        <h2 className="text-center book-even" >
            even amidst horrific scandal...
        </h2>
        <h1 className="text-center book-title" >
            Passing the Faith onto Loved Ones
        </h1>
        <h2 className="text-center book-lit" >
            How God Has Lit A Path for Catholics
        </h2>
      </header>
      <main className="book-body" >

        <div className="book-section0">
          <Card bg="light">
            <Card.Body>
              <Card.Text>
                <p className="ralph-quote"><span style={{fontWeight: "bold"}}>&ldquo;</span>
                  Deacon John is a clear thinker and clear writer and has written a book 
                  that will be a real contribution to Passing the Faith onto Loved Ones.
                  <span style={{fontWeight: "bold"}}>&rdquo;</span>
                </p>
                <p><span style={{fontWeight: "bold"}}>~ Ralph Martin, S.T.D.</span></p>
                <p>Director of Graduate Theology Programs in the New Evangelization, <br />
                Sacred Heart Major Seminary, Archdiocese of Detroit</p>
                <p>President, <a href="https://www.renewalministries.net/"
                    target="_blank" rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    Renewal Ministries
                  </a>
                </p>
                <p>Author of many books including:
                <span style={{fontStyle: "italic"}}> A Church in Crisis</span> (new!),  
                <span style={{fontStyle: "italic"}}> The Urgency of the New Evangelization</span>, 
                <span style={{fontStyle: "italic"}}> The Final Confrontation</span>, 
                <span style={{fontStyle: "italic"}}> Will Many Be Saved</span>, 
                <span style={{fontStyle: "italic"}}> and The Fulfillment of All Desire</span>
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </div >

        <div className="book-section0">
        <div className="book-section1 book-back-cover">For those who...</div>
          <div className="book-section2">
            Love the Church...
          </div>
          <div className="book-description">
            <p>
              Trying to pass the faith onto loved ones is not only difficult because
              of horrible scandals, but also because of widespread disbelief,
              doubt, and often having no need for God in this life or the next.
              This short book puts the scandals in perspective and lays out a case 
              for why it makes so much sense to believe as a Catholic 
              based on evidence and common sense.
            </p>
            <p>
              It greatly concerns me, as a deacon, to look at the empty seats at Mass,
              and wonder what it's like when people die and must stand before God the Father 
              after having neglected and forgotten his beloved son Jesus crucified.
              Therefore, I have written, <span style={{ fontStyle: "italic" }}>Passing the Faith onto Loved Ones</span>, 
              to aid our conversations with those we care about so they may come to believe
              there is one God, that Jesus is His only beloved son, and that, historically speaking,
              Jesus established one Church to nourish and guide us.
            </p>
            <p>
              This book presents 3 reasons for believing in God and 12 more for believing
              in Jesus and his Church. It also deals with common challenges, such as, suffering,
              Biblical violence, sexual morality, and women's ordination. It is my hope
              and prayer that this case will ultimately help people believe and be prepared to stand before
              God the Father as Simeon was when he prayed, "Lord, now your servant is ready to 
              go to you in peace." (Luke 2:29)
            </p>
          </div>
        </div>

        <div className="book-section0">
          <div className="book-section1 book-the-book">The Book</div>
          <div className="book-section2">Amazon</div>
          <div className="book-links">
            <a href="https://www.amazon.com/dp/1659148014?ref_=pe_3052080_397514860"
                  target="_blank" rel="noopener noreferrer"
                  onClick={() => gaTrack('Resources', 'CoverClickPassingTheFaith')}
                  style={{ textDecoration: "none" }}
              >
              <Image src={passingTheFaith} 
                className="img-fluid rounded" 
                title="Passing the Faith onto Loved Ones"
              />
            </a>
          </div>

          <div className="book-button-outer" >
            <div className="book-links">
              <a href="https://www.amazon.com/dp/1659148014?ref_=pe_3052080_397514860"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'Amazon-PassingTheFaithButton')}
                style={{ textDecoration: "none" }}>
                <span
                  className="book-button"
                  style={{ backgroundColor: "#d12233" }}
                >
                  Amazon Books
              </span>
              </a>
              <div className="book-price">(Kindle 4.95 US, Paperback 7.95 US)</div>
            </div>
          </div>

          <div className="book-section2">YouTube</div>
          <div className="book-links">
            <a href="https://youtu.be/TdiLd_zkD_w"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'Book-YouTube')}
            >
            Passing the Faith onto Loved Ones</a>
            <div className="book-span">(Narration: 2 hours, 56 minutes)</div>
          </div>
          <div className="book-section2">Manuscript</div>
          <div className="book-links">
              <span className="book-link" >
                <a href={wordbook} onClick={ () => gaTrack('Resources', 'Book-Word') } 
                  >Word doc</a></span>
              <span className="book-link" >
                <a href={pdfbook} 
                  target="_blank" rel="noopener noreferrer"
                  onClick={ () => gaTrack('Resources', 'Book-Pdf') }
                  >Pdf</a></span>
              <span><a href="https://docs.google.com/document/d/1SJcaPTyjkF8jd6ZHlsqWTKR9rIR_H41Eeuen5GemSPE/edit?usp=sharing" 
              target="_blank" rel="noopener noreferrer"
              onClick={ () => gaTrack('Resources', 'Book-GoogleDoc') }
              >Google doc</a></span>
          </div>
        </div>

        <div className="book-section0">
          <div className="book-section1 book-the-video">The Video</div>
          <div className="book-section2">YouTube</div>

          <div className="book-description">
            The other version of this book is <a href="https://www.ItMakesSenseToBelieve.com"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'SiteLink-FromSomeone')}
              style={{ fontStyle: "italic" }} >It Makes Sense to Believe</a>, tailored
            for those who doubt, disbelieve, or are otherwise lukewarm. The following video
            is an invitation to send to fallen-away loved ones so they might
            consider this book's simple case for the faith and come to Mass.
          </div>

          <div className="book-links">
            <a href="https://youtu.be/GQb7dBQy6lM"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'FromSomeone-YouTube')}
              >
              <Image src={fromSomeone} 
                    className="img-fluid rounded" 
                    title="From Someone Who Loves You"
                    style={{border: "1px solid #DCDCDC"}}
                    />
              </a>
            <div className="book-span">(11 minutes)</div>
          </div>

          <div className="book-description" style={{marginTop: "2rem"}}>
            There is also a video version of the book 
            to strengthen Catholics' belief and zeal. And this has been
            segmented into 12 short videos to provide
            more sharing options.
          </div>

          <div className="book-links">
            <a href="https://youtu.be/mV3tP0w3Edc"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'TheVideo-YouTube')}
              >
              <Image src={youTube} 
                    className="img-fluid rounded" 
                    title="It Makes Sense to Believe - The Video"
                    />
              </a>
            <div className="book-span">(83 minutes)</div>
          </div>

          <div className="book-section2">Short Videos</div>
          <div className="book-links">

            {/* From Someone Who Loves You */}
            <a href="https://youtu.be/GQb7dBQy6lM"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'From-Someone')}
              style={{ fontWeight: "bold", fontStyle: "italic" }}
            >
              From Someone Who Loves You
            </a>
            <span className="book-span2"> (11&nbsp;min), </span>

            {/* Crosses - Rosie */}
            <a href="https://www.youtube.com/watch?v=hRQq8VR7Ne4"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'crosses')}
            >
              Crosses - Rosie
            </a>
            <span className="book-span2"> (4&nbsp;min), </span>

            {/* 1 */}
            <a href="https://youtu.be/f9Kn_ZVVARI"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '01-should-we')}
            >
              1-Should We Care?
            </a>
            <span className="book-span2"> (9&nbsp;min), </span>

            {/* 2 */}
            <a href="https://youtu.be/qAPaOiQOKHE"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '02-truth')}
            >
              2-Importance of Truth
            </a>
            <span className="book-span2"> (3&nbsp;min), </span>

            {/* 3 */}
            <a href="https://youtu.be/egVKsIpmIqE"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '03-is-god-dead')}
            >
              3-Is God Dead?
            </a>
            <span className="book-span2"> (3&nbsp;min), </span>

            {/* 4 */}
            <a href="https://youtu.be/m1BMtAtO23o"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '04-reveal')}
            >
              4-Did God Reveal Himself?
            </a>
            <span className="book-span2"> (11&nbsp;min), </span>

            {/* 5 */}
            <a href="https://youtu.be/rrfp4G5s6mg"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '05-Love')}
              style={{ fontWeight: "bold", fontStyle: "italic" }}
            >
              5-Love
            </a>
            <span className="book-span2"> (5&nbsp;min), </span>

            {/* 6 */}
            <a href="https://youtu.be/JHsuE3PYMq8"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '06-evidence')}
            >
              6-Evidence
            </a>
            <span className="book-span2"> (4&nbsp;min), </span>

            {/* 7 */}
            <a href="https://youtu.be/JUr7l7o6vG8"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '07-Evil')}
              style={{ fontWeight: "bold", fontStyle: "italic" }}
            >
              7-Evil
            </a>
            <span className="book-span2"> (20&nbsp;min), </span>

            {/* 8 */}
            <a href="https://youtu.be/0Fj5wAPt8UQ"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '08-suffering-death')}
            >
              8-Suffering &amp; Death
            </a>
            <span className="book-span2"> (9&nbsp;min), </span>

            {/* 9 */}
            <a href="https://youtu.be/16lbIYsre3Y"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '09-little-faith')}
            >
              9-A Little Faith Is Needed
            </a>
            <span className="book-span2"> (8&nbsp;min), </span>

            {/* 10 */}
            <a href="https://youtu.be/8wDDdkTDI8M"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '10-sex')}
            >
              10-Sex, Sin &amp; Scandal
            </a>
            <span className="book-span2"> (7&nbsp;min), </span>

            {/* 11 */}
            <a href="https://youtu.be/BCjCYcULNMc"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '11-women')}
            >
              11-Women's Ordination
            </a>
            <span className="book-span2"> (5&nbsp;min), </span>

            {/* 12 */}
            <a href="https://youtu.be/C84uxYDg9mU"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', '12-making-it')}
            >
              12-Making It into Heaven
            </a>
            <span className="book-span2"> (9&nbsp;min), </span>

          {/* Salvation: Church in Crisis */}
          <a href="https://youtu.be/PHJmtRYd4XA"
            target="_blank" rel="noopener noreferrer"
            onClick={() => gaTrack('Resources', 'YouTube-Salvation')}
            style={{ fontWeight: "bold", fontStyle: "italic" }}
          >
            Salvation: Church in Crisis
          </a>
          <span className="book-span2"> (21&nbsp;min), </span>

          {/* Army in the Pews */}
          <a href="https://youtu.be/NyY_FUx4HoY"
            target="_blank" rel="noopener noreferrer"
            onClick={() => gaTrack('Resources', 'YouTube-Army')}
          >
            Army in the Pews
          </a>
          <span className="book-span2"> (12&nbsp;min), </span>

          {/* Intuitive Case */}
          <a href="https://youtu.be/2wrPySQKSlA"
            target="_blank" rel="noopener noreferrer"
            onClick={() => gaTrack('Resources', 'YouTube-Intuitive')}
          >
          An Intuitive Case for Jesus&apos; Dire Words
          </a>
          <span className="book-span2"> (14&nbsp;min), </span>

          {/* Sharing Our Reasons */}
          <a href="https://youtu.be/NqnOhfKP5-U"
            target="_blank" rel="noopener noreferrer"
            onClick={() => gaTrack('Resources', 'YouTube-Sharing')}
          >
          Sharing with Loved Ones the Reasons for Our Hope
          </a>
          <span className="book-span2"> (15&nbsp;min)</span>

          </div>
        </div>

        <div className="book-section0">
          <div className="book-section1 book-synopsis">Synopsis</div>
          <div className="book-description">
            Pressed for time? This lays out main points of book
            but with fewer details and anecdotes.
          </div>
          <div className="book-section2">YouTube</div>
            <div className="book-links">
              <a href="https://www.youtube.com/watch?v=TytyM8abiXQ" target="_blank" rel="noopener noreferrer"
                onClick={ () => gaTrack('Resources', 'Synopsis-YouTube') }
               >
                Passing the Faith onto Loved Ones</a>
                <div className="book-span">(40 minutes)</div>
            </div>
          <div className="book-section2">Manuscript</div>
          <div className="book-links">
              <span className="book-link" >
                <a href={worddoc} onClick={ () => gaTrack('Resources', 'Synopsis-Word') } >Word doc</a>
              </span>
              <span className="book-link" >
                <a href={pdf} target="_blank"  rel="noopener noreferrer" 
                  onClick={ () => gaTrack('Resources', 'Synopsis-Pdf') }
                >Pdf</a>
                </span>
              <span>
                <a href="https://docs.google.com/document/d/1XiPPuUPPyU_so_Cr4PjUk4iKyQTAk395BmUGJjFv1ZY/edit?usp=sharing" 
                  target="_blank"  rel="noopener noreferrer"
                  onClick={ () => gaTrack('Resources', 'Synopsis-GoogleDoc') }
                  >Google doc</a>
              </span>
          </div>
        </div>

        <div className="book-section0">
          <div className="book-section1 salvation-crisis" >
            Salvation: Church in Crisis
          </div>
          <div className="book-description">
            This article refers to a recent column (December 2020) by 
            Cardinal George Pell and to an earlier one by Cardinal 
            Avery Dulles. It explores what underlies the widespread 
            presumption that almost everyone makes it to heaven 
            despite the obvious meaning of Jesus' words. Then it reconsiders 
            Jesus' difficult teaching in light of God who is love, the 
            crucifixion of his only beloved son, and the intimate reality of heaven.
            The article can be read, downloaded or watched on 
            YouTube.
          </div>
          <div className="book-button-outer" >
            <div className="book-links">
              <a href="http://www.IntentionallyCatholic.com/Salvation"
                target="_blank" rel="noopener noreferrer"
                onClick={() => gaTrack('Resources', 'GotoSalvation')}
                style={{ textDecoration: "none" }}>
                <span
                  className="book-button salvation-crisis"
                  style={{ backgroundColor: "#9b0000" }}
                >
                  Goto Salvation
              </span>
              </a>
            </div>
          </div>

        </div>

        <div className="book-section0 margin-top2">

          <div className="book-section1" >
            Other Articles
          </div>
          <div className="book-description">
            (Read or Listen)
          </div>

          <div className="book-description" style={{fontStyle: "italic", fontSize: "150%"}}>
            Army in the Pews
          </div>
          <div className="book-description">
            An important story about a passing opportunity to bring Catholics back to church. 
            See <a
             href="https://www.IntentionallyCatholic.com/Army"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'PassingArmy')}
               >
              IntentionallyCatholic.com/<b>Army</b>
            </a>.
          </div>

          <div className="book-description" style={{fontStyle: "italic", fontSize: "150%"}}>
            An Intuitive Case for Jesus&apos; Dire Words
          </div>
          <div className="book-description">
            Contrary to popular &ldquo;feelings,&rdquo; this article considers 
            the accuracy of Jesus&apos; words in light of how much God loves us, 
            the crucifixion of his only beloved son, and the intimate 
            reality of heaven. <a
             href="https://www.IntentionallyCatholic.com/Dire"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'PassingDire')}
               >
              IntentionallyCatholic.com/<b>Dire</b>
            </a>.
          </div>

          <div className="book-description" style={{fontStyle: "italic", fontSize: "150%"}}>
            Sharing with Loved Ones the Reasons for Our Hope
          </div>
          <div className="book-description">
            This brief article is all that’s needed to initiate conversations with loved ones and bring them back to church. 
            See <a
             href="https://www.IntentionallyCatholic.com/Share"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'PassingShare')}
               >
              IntentionallyCatholic.com/<b>Share</b>
            </a>.
          </div>

        </div>



        <div className="book-section0">

          <div className="book-section1 how-to-use-book" >
            How to Use Books &amp; Video?
          </div>

          <div className="book-description">
            The books, video, and narrations on YouTube were created so people may
            read, watch or listen to this simple case for our faith and 
            be prepared to stand before God our Father. If you agree with the content
            and approach, <span className="font-weight-bold">please promote it</span>. 
            The manuscripts and YouTube presentations are free. And the Amazon 
            versions are inexpensive.
          </div>

          <div className="book-description">
            A <span className="font-weight-bold">bishop</span> can 
            use <span style={{ fontStyle: "italic" }} >Passing the Faith 
            onto Loved Ones</span> to help his priests, 
            deacons and teachers fully believe. Given the book’s simplicity, however, 
            more intellectual sources may also be desired. That said, its ease of 
            understanding for busy laypeople might be an example worth 
            considering. The key point is leaders of the Church at all levels  
            must themselves fully believe to be most successful 
            at bringing people back to Mass.
          </div>

          <div className="book-description">
            In a similar way, <span className="font-weight-bold">pastors</span>, 
            priests, deacons and teachers need to help their active members 
            fully believe so they can in turn have conversations with loved ones and 
            pass on the faith.&nbsp;
             <a href="https://www.ItMakesSenseToBelieve.com"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'SiteLinkItMakesSense')}
              style={{ fontStyle: "italic" }} >
              It Makes Sense to Believe</a>, the other book,  
            is meant for those who lack interest, doubt, disbelieve, or otherwise feel 
            practicing the faith is unnecessary. By default, I either hand out or refer 
            people to this book.
          </div>

          <div className="book-description">
          Whenever we’re preaching, teaching about the faith, 
          or having conversations about it, we must always include 
          some practical reasons for why we believe this makes sense. 
          Over time, people will start to get it.
          </div>

          <div className="book-description">
          Please visit the book websites for links to YouTube videos, 
          narrations, manuscripts, Kindle version, and Amazon paperback.
          For bulk paperback orders, please contact me.
          </div>

          <div className="book-description">
            Lastly, band with kindred clergy 
            who believe all God's words and 
            want to share in Christ's saving mission: <a
             href="https://www.IntentionallyCatholic.com/Clergy"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'KindredClergy')}
              style={{ fontStyle: "italic" }} >
              IntentionallyCatholic.com/<b>Clergy</b>
            </a>. 
          </div>
        </div>

        <div className="book-section0" style={{ marginBottom: "4rem" }}>
            <div className="book-section1 book-the-video" >
              Intentionally Catholic
            </div>
            <div className="book-description">
              Resources for clergy and laity to shore up the faith 
              of parishioners and help pass the faith on to loved ones.
            </div>
            <div className="book-button-outer" >
              <div className="book-links">
                <a href="http://www.IntentionallyCatholic.com"
                  target="_blank" rel="noopener noreferrer"
                  onClick={() => gaTrack('Resources', 'Intentionally-Main')}
                  style={{ textDecoration: "none" }}>
                  <span
                    className="book-button"
                    style={{ backgroundColor: "#ca8e4c" }}
                  >
                    Goto Main Page
                  </span>
                </a>
              </div>
            </div>
          </div>

          <div className="book-section0 margin-top" style={{marginBottom: "5rem"}}>
          <div className="book-section1 book-rosie" >
            Rosie
          </div>
          <div className="book-description">
            Five days before Christmas 2019, at 5:00am, Charlie discovered his daughter's lifeless body. Rosie was a 
            perfectly healthy 2-year-old. She was Laura and Charlie's only child, and our only grandchild. In the following
            links, they discussed what happened, and how their Catholic faith pulled them through.
          </div>

          <div className="book-description">
            In this secular podcast, <b>Laura and Charlie</b> told their story and provided some raw details. The 
            interviewer was excellent. They also wove in how their faith helped. <a
             href="https://open.spotify.com/episode/1sP0f7b2Hky0Vqa4aKcyE8"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'rosie1')}
              style={{ fontStyle: "italic" }} >
              Tough Mudder Podcast
            </a>. 
          </div>

          <div className="book-description">
            In this video, <b>Laura</b> spoke to 300 women at Long Beach Catholic Community 
            during an evening of recollection. Her presentation begins after the 22 minute mark  
            and they watch a 2 minute video which is not visible. <a
             href="https://www.youtube.com/watch?v=zAQtXncFZmU"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'rosie2')}
              style={{ fontStyle: "italic" }} >
              A Rose in Heaven | BC Ladies Event
            </a>. 
          </div>

          <div className="book-description">
            In this video, <b>Charlie</b> spoke to 50 Beach Catholic men during a day of retreat. <a
             href="https://www.youtube.com/watch?v=SGwfpKVPto8"
              target="_blank" rel="noopener noreferrer"
              onClick={() => gaTrack('Resources', 'rosie3')}
              style={{ fontStyle: "italic" }} >
              Charlie's Presentation
            </a>. 
          </div>
        </div>

        <Share />

        <div className="book-section0" style={{marginTop: "3rem"}}>
          <div className="book-section1 contact-info">Contact Information</div>
          <div className="book-description">
            Please contact me at:
            <br className="show-hide" /> <a href="mailto: DeaconJohnBeagan@gmail.com">DeaconJohnBeagan@gmail.com</a>
            <br className="show-hide" /> if you have any questions or feedback. 
            <br />God bless!
            <div className="signature">
              Deacon John Beagan
            </div>
            <div className="serving">
              Serving Sacred Heart &amp; Saint Patrick parishes in Watertown, MA
            </div>
            <div className="serving">
              Archdiocese of Boston
            </div>
          </div>
        </div>

      </main>

      <footer className="text-center book-footer">
        <div>
          <a href="https://www.IntentionallyCatholic.com"
            target="_blank" rel="noopener noreferrer"
            onClick={() => gaTrack('Resources', 'IntentionallyCatholicLink')}
            style={{ fontStyle: "italic", fontWeight: "400" }}>
            Intentionally Catholic .com
          </a>
        </div>
        <div className="rosie">
          <a href="https://www.RosieBeagan.com"
            target="_blank" rel="noopener noreferrer"
            onClick={() => gaTrack('Resources', 'RosieLink')}
            style={{ fontStyle: "italic", fontWeight: "500" }}>
            <Image src={rose} 
            className="img-fluid rounded" 
            title="Rose Elizabeth Foundation" 
            width="40px"
          />
            Rosie
          </a>
        </div>
      </footer>

    </Container>
  );
}

export default App;
